import React, { FunctionComponent } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { AppDetail } from "../AppDetail";
import { Header } from "../Header";
import { PrivacyPolicy } from "../PrivacyPolicy";
import { Body } from "../Body";
import { Footer } from "../Footer";
import { App } from "../App";
import { DefaultRoute } from "../DefaultRoute";
import { JustBreatheSwitch } from "../JustBreatheSwitch";
import { TermsConditions } from "../TermsConditions";
import { ExternalRedirect } from "../ExternalRedirect";

export const Router: FunctionComponent = () => (
    <BrowserRouter>
        <App>
            <Header />
            <Body>
                <Switch>
                    <Route path="/justbreathe/links" component={JustBreatheSwitch} />
                    <ExternalRedirect path="/store" url="https://play.google.com/store/apps/dev?id=7006181994130331856" />
                    <ExternalRedirect path="/diceroller/store" url="https://play.google.com/store/apps/details?id=com.pouproduction.diceroller" />
                    <ExternalRedirect path="/fingerrace/store" url="https://play.google.com/store/apps/details?id=com.pouproduction.fingerrace" />
                    <ExternalRedirect path="/justbreathe/store" url="https://play.google.com/store/apps/details?id=com.pouproduction.justbreathe" />
                    <ExternalRedirect path="/mealdivider/store" url="https://play.google.com/store/apps/details?id=com.pouproduction.mealdivider" />
                    <ExternalRedirect path="/sets/store" url="https://play.google.com/store/apps/details?id=com.pouproduction.sets" />
                    <Route path="/:id/privacy" component={PrivacyPolicy} />
                    <Route path="/:id/terms" component={TermsConditions} />
                    <Route path="/:id/detail" component={AppDetail} />

                    {/* Backup */}
                    <Route path="/app/justbreathe/links" component={JustBreatheSwitch} />
                    <Route path="/app/:id/privacy" component={PrivacyPolicy} />
                    <Route path="/app/:id/detail" component={AppDetail} />
                    {/* Backup */}

                    <DefaultRoute />
                </Switch>
            </Body>
            <Footer />
        </App>
    </BrowserRouter>
);
