import React, { FunctionComponent } from "react";
import Markdown from "react-markdown";

import { termsConditions } from "./assets";
import "./TermsConditions.scss";

export type TermsConditionsProps = {
    match: {
        params: {
            id: string,
        },
    },
}

export const TermsConditions: FunctionComponent<TermsConditionsProps> = ({ match }) => (
    <div className="terms-conditions">
        <Markdown source={termsConditions[match.params.id]} />
    </div>
);
