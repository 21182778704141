import React, { FunctionComponent } from "react";

import { AppItem } from "../AppItem";
import { appDataList } from "../../constants";
import "./AppList.scss";

export const AppList: FunctionComponent = () => (
    <div className="app-list">
        {appDataList.map(({ id, title, description, logo, subtitle }) => (
            <AppItem
                key={id}
                {...{ id, title, description, logo, subtitle }}
            />
        ))}
    </div>
);
