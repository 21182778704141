import React, { FunctionComponent } from "react";
import Markdown from "react-markdown";

import { privacyPolicy } from "./assets";
import "./PrivacyPolicy.scss";

export type PrivacyPolicyProps = {
    match: {
        params: {
            id: string,
        },
    },
}

export const PrivacyPolicy: FunctionComponent<PrivacyPolicyProps> = ({ match }) => (
    <div className="privacy-policy">
        <Markdown source={privacyPolicy[match.params.id]} />
    </div>
);
