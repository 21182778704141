import diceroller from "./diceroller";
import mealdivider from "./mealdivider";
import justbreathe from "./justbreathe";
import fingerrace from "./fingerrace";
import sets from "./sets";

export const privacyPolicy: { [id: string]: string } = {
    diceroller,
    mealdivider,
    justbreathe,
    fingerrace,
    sets,
};
