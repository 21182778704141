import React, { Fragment } from "react";

import { AppData } from "./types";
import diceRollerLogo from "./assets/DiceRoller.png";
import fingerRaceLogo from "./assets/FingerRace.png";
import justBreatheLogo from "./assets/JustBreathe.png";
import mealDividerLogo from "./assets/MealDivider.png";

export const appDataList: AppData[] = [
    {
        id: "diceroller",
        title: "Dice Roller: Roll, shift, save",
        subtitle: "Roll & hold dice, adjust & save dice configs, shift score, explore dice world",
        description: (
            <Fragment>
                <p>Have you lost your dice again? You don't need it anymore.</p>
                <p>You can tap, swipe or shake to roll up to 100 dice with 100 sides.</p>
                <p>How many dice games do you know? Discover the rules of several dice games.</p>
            </Fragment>
        ),
        logo: diceRollerLogo,
        storeUrl: "https://play.google.com/store/apps/details?id=com.pouproduction.diceroller",
        pageUrl: "https://diceroller.pavelpo.cz/",
        pageType: "landing",
        videoUrl: "https://www.youtube.com/watch?v=iOszpZQU1Js",
    },
    {
        id: "justbreathe",
        title: "JustBreathe: Sleep and calm",
        subtitle: "Breathing exercises for better sleep, calm or concentration.",
        description: (
            <Fragment>
                <p>It's nighttime and you <b>can't sleep again?</b></p>
                <p>Didn't a second coffee help you <b>study for the exam?</b></p>
                <p>Do you want to enjoy the present moment, but <b>have your head full of thoughts?</b></p>
            </Fragment>
        ),
        logo: justBreatheLogo,
        storeUrl: "https://play.google.com/store/apps/details?id=com.pouproduction.justbreathe",
    },
    {
        id: "fingerrace",
        title: "Finger Race: Fast and Furious",
        subtitle: "Beat your friends in tap speed! An easy game for two players.",
        description: (
            <Fragment>
                <p>Do you tap on your phone all day long? Let's turn it into a game! How fast can you tap? Can you beat your friends?</p>
                <p>Finger Race is a simple game for one or two players.</p>
            </Fragment>
        ),
        pageUrl: "https://fingerrace.com/",
        pageType: "statistics",
        logo: fingerRaceLogo,
        storeUrl: "https://play.google.com/store/apps/details?id=com.pouproduction.fingerrace"
    },
    {
        id: "mealdivider",
        title: "Meal Divider: Servings Helper",
        subtitle: "Helper when dividing meal into servings – with or without dishes on the scale.",
        description: (
            <Fragment>
                <p>Simplify your path to your dream body 💪</p>
                <p>Do you have a pan full of food on the kitchen scale? Is it too much for one serving? Divide the food into precise portions.</p>
            </Fragment>
        ),
        logo: mealDividerLogo,
        storeUrl: "https://play.google.com/store/apps/details?id=com.pouproduction.mealdivider"
    },
];

type DataList = {
    [id: number]: {
        id: number,
        url: string,
    },
};

const urls: { [id: number]: string } = {
    1: "https://www.novinky.cz/zena/zdravi/clanek/jak-usnout-za-par-minut-pomohou-triky-vojaku-nebo-metoda-4-7-8-40310692",
    2: "https://www.jobfairs.eu/magazin/5-overenych-dychacich-cviceni,-diky-kterym-v-klidu",
    3: "https://www.rehabilitace.info/zdravotni/krabicove-dychani-co-je-to-a-jak-na-nej/",
    4: "https://youtu.be/xVupnWkz3IY",
    5: "https://youtu.be/N2wR1OWhD4s",
    6: "https://youtu.be/p8fjYPC-k2k",
    7: "https://youtu.be/lFfTwJo_cWM",
    8: "https://www.youtube.com/watch?v=odADwWzHR24&t=23s",
}

export const justBreatheLinks: DataList = Object.entries(urls).reduce((result, [id, url]) => ({
    ...result,
    [id]: {
        id,
        url,
    },
}), {})
