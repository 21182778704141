import { PageType } from "../../types";
import openLandingPageImage from "./open_landing_page.png"
import openStatisticsImage from "./open_statistics.png"

export const pageTypeToButtonImage = (pageType: PageType) => {
    switch (pageType) {
        case "landing": return openLandingPageImage;
        case "statistics": return openStatisticsImage;
    }
}

export const pageTypeToButtonAlt = (pageType: PageType) => {
    switch (pageType) {
        case "landing": return "Open landing page";
        case "statistics": return "Open statistics";
    }
}
