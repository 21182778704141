import React, { FunctionComponent } from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import { justBreatheLinks } from "../../constants";
import { ExternalRedirect } from "../ExternalRedirect";
import { DefaultRoute } from "../DefaultRoute";

export const JustBreatheSwitch: FunctionComponent = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            {Object.values(justBreatheLinks).map(({ id, url }) => (
                <ExternalRedirect path={`${path}/${id}`} {...{ url }} />
            ))}
            <DefaultRoute />
        </Switch>
    );
};
