import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import "./Header.scss";
import logo from "./assets/PouProduction.png";

export const Header: FunctionComponent = () => (
    <div className="header">
        <Link to="">
            <div className="header__left-content">
                <img alt="Logo" src={logo} className="header__logo" />
                <div className="header__title">Pou Production</div>
            </div>
        </Link>
        <div className="header__right-content">
            <a
                className="header__menu-item"
                href="mailto:info@pouproduction.com"
            >
                info@pouproduction.com
            </a>
        </div>
    </div>
);
