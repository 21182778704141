import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import { bemClassNames } from "../../utils";
import "./AppItem.scss";

export type AppItemProps = {
    id: string;
    title: string;
    subtitle: string;
    logo: any;
    disabled?: boolean;
}

const classNames = bemClassNames("app-item");

export const AppItem: FunctionComponent<AppItemProps> = ({ id, title, subtitle, logo, disabled }) => (
    <Link to={`${id}/detail`} className={classNames({ disabled })}>
        <img className={classNames("logo")} alt="Logo" src={logo} />
        <div className={classNames("title")}>{title}</div>
        <div className={classNames("subtitle")}>{subtitle}</div>
    </Link>
);
