import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import { appDataList } from "../../constants";
import { bemClassNames } from "../../utils";
import { AppData } from "../../types";
import playVideoImage from "./play_video.png"
import { pageTypeToButtonAlt, pageTypeToButtonImage } from "./utils";
import "./AppDetail.scss";

export type AppDetailProps = {
    match: any;
}

const classNames = bemClassNames("app-detail");

export const AppDetail: FunctionComponent<AppDetailProps> = ({ match }) => {
    const { id, title, logo, description, subtitle, storeUrl, pageUrl, status, videoUrl, pageType } = appDataList.find(({ id }) => id === match.params.id) as AppData;

    return (
        <div className={classNames()}>
            <img alt="Logo" className={classNames("logo")} src={logo} />
            <div className={classNames("info")}>
                <div className={classNames("content")}>
                    <div className={classNames("title")}>{title}</div>
                    <div className={classNames("subtitle")}>{subtitle}</div>
                    <div className={classNames("description")}>{description}</div>
                    {storeUrl && (
                        <div className={classNames("links")}>
                            <a
                                href={storeUrl}
                                title={"A link for the Android version"}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className={classNames("link-image")}
                                    alt="Get it on Google Play"
                                    src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                                />
                            </a>
                            {pageUrl && pageType && (
                                <a
                                    href={pageUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className={classNames("landing-page-link-image")}
                                        alt={pageTypeToButtonAlt(pageType)}
                                        src={pageTypeToButtonImage(pageType)}
                                    />
                                </a>
                            )}
                            {videoUrl && (
                                <a
                                    href={videoUrl}
                                    title="A link to the app video"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className={classNames("landing-page-link-image")}
                                        alt="Play video"
                                        src={playVideoImage}
                                    />
                                </a>
                            )}
                        </div>
                    )}
                    {status && (
                        <div className={classNames("status")}>{status}</div>
                    )}
                </div>
                <div className={classNames("footer")}>
                    <Link to={`/${id}/privacy`}>
                        <div className={classNames("privacy")}>Privacy Policy</div>
                    </Link>
                </div>
            </div>
        </div>
    );
};
