import React, { FunctionComponent } from "react";
import { Route } from "react-router-dom";

export type ExternalRedirectProps = {
    path: string;
    url: string;
};

export const ExternalRedirect: FunctionComponent<ExternalRedirectProps> = ({ path, url }) => (
    <Route
        exact={true}
        component={() => {
            window.location.href = url;
            return null;
        }}
        {...{ path }}
    />
);
