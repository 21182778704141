import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import { Router } from "./components/Router";
import "./index.css";

ReactDOM.render(<Router />, document.getElementById("root"));

serviceWorker.unregister();
