import React, { FunctionComponent } from "react";

import "./Footer.scss";

export const Footer: FunctionComponent = () => (
    <div className="footer">
        <a
            href="https://pavelpo.cz/"
            className="footer__link"
            target="_blank"
            rel="noopener noreferrer"
        >
            Pavel Pospíšil 2019–{(new Date()).getFullYear()}
        </a>
        <div className="footer__cookies">
            This page uses cookies.
        </div>
    </div>
);
